import { render, staticRenderFns } from "./SimplePostParameterDialog.vue?vue&type=template&id=0fb0882f&"
import script from "./SimplePostParameterDialog.vue?vue&type=script&lang=js&"
export * from "./SimplePostParameterDialog.vue?vue&type=script&lang=js&"
import style0 from "./SimplePostParameterDialog.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QInput,QIcon,QPopupProxy,QDate,QBtn,QBtnDropdown,QCardActions});qInstall(component, 'directives', {ClosePopup});
