<template>
  <q-btn-dropdown
    dense
    unelevated
    color="secondary"
    label="Печать"
    icon="print"
    no-caps
  >
    <q-list dense class="pagradient q-px-sm q-py-md">
      <q-item clickable>
        <q-item-section>
          <q-item-label>
            <div class="row">
              <div class="col-auto">
                <q-btn-dropdown
                  color="primary-light"
                  class="canvaformselector text-left q-my-xs "
                  content-class="fheightdrop"
                  dense
                  unelevated
                  outline
                  no-caps
                  split
                  @click="
                    print({
                      doctype: 'Envelope',
                      save: false,
                      addprice: addPrice,
                      addndstoprice: addNdsToPrice,
                      addndstopriceservices: addNdsToPriceServices
                    })
                  "
                >
                  <template v-slot:label>
                    <span class="q-pl-xs q-pr-xs">Конверт</span>
                  </template>
                  <div class="row no-wrap q-pa-md pagradient">
                    <div class="column">
                      <q-list dense>
                        <q-item
                          v-for="(item, index) in formatlist"
                          :key="index"
                          clickable
                          v-close-popup
                          @click.stop="
                            curformat = item.value;
                            updateCommonSettings();
                          "
                          active-class="selected-frm"
                          :active="curformat.value == item.value"
                          class="fflist"
                        >
                          <q-item-section>
                            <q-item-label class="cuttedlabel">{{
                              item.label
                            }}</q-item-label>
                          </q-item-section>
                          <q-item-section side>
                            <div class="text-grey-8 q-gutter-xs">
                              <q-btn
                                flat
                                dense
                                round
                                icon="edit"
                                size="sm"
                                color="white"
                                @click="openSettings"
                              />
                            </div>
                          </q-item-section>
                        </q-item>
                      </q-list>
                      <a
                        class="addformatbtn"
                        href="/support/envelope/create-format/"
                        target="_blank"
                      >
                        Создать свой макет
                      </a>
                    </div>

                    <q-separator
                      v-show="false"
                      vertical
                      inset
                      class="q-mx-md whitesep"
                    />
                  </div>
                </q-btn-dropdown>
              </div>
              <div class="col-auto q-pl-md q-pt-xs">
                <q-toggle
                  v-model="addPrice"
                  checked-icon="check"
                  color="accent"
                  size="xs"
                  label="с весом и ценой"
                  unchecked-icon="clear"
                />
                <div v-show="addPrice">
                  <div>
                    <q-toggle
                      v-model="addNdsToPrice"
                      checked-icon="check"
                      color="accent"
                      size="xs"
                      label="пересылка с НДС"
                      unchecked-icon="clear"
                    />
                  </div>
                  <div>
                    <q-toggle
                      v-model="addNdsToPriceServices"
                      checked-icon="check"
                      color="accent"
                      size="xs"
                      label="услуги с НДС"
                      unchecked-icon="clear"
                    />
                  </div>
                </div>
              </div>
            </div>
          </q-item-label>
        </q-item-section>

        <q-item-section side class="justify-start-important">
          <q-btn
            class="q-ml-xl bg-ddbtn text-white"
            size="sm"
            unelevated
            dense
            icon="save"
            v-close-popup
            @click.stop="
              print({
                doctype: 'Envelope',
                save: true,
                addprice: addPrice,
                addndstoprice: addNdsToPrice,
                addndstopriceservices: addNdsToPriceServices
              })
            "
          >
            <q-tooltip
              anchor="center right"
              content-class="bg-accent fs14px"
              self="center left"
              :offset="[10, 10]"
            >
              Сохранить в PDF
            </q-tooltip>
          </q-btn>
        </q-item-section>
      </q-item>

      <q-item
        clickable
        v-close-popup
        @click="print({ doctype: 'Invoice', save: false })"
      >
        <q-item-section>
          <q-item-label>Уведомления Ф-119</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            class="q-ml-xl bg-ddbtn text-white"
            size="sm"
            unelevated
            dense
            icon="save"
            v-close-popup
            @click.stop="print({ doctype: 'Invoice', save: true })"
          >
            <q-tooltip
              anchor="center right"
              content-class="bg-accent fs14px"
              self="center left"
              :offset="[10, 10]"
            >
              Сохранить в PDF
            </q-tooltip>
          </q-btn>
        </q-item-section>
      </q-item>

      <q-item
        clickable
        v-close-popup
        @click="print({ doctype: 'Inventory', save: false })"
        class="q-mb-md"
      >
        <q-item-section>
          <q-item-label>Опись вложений Ф-107</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            class="q-ml-xl bg-ddbtn text-white"
            size="sm"
            unelevated
            dense
            icon="save"
            v-close-popup
            @click.stop="print({ doctype: 'Inventory', save: true })"
          >
            <q-tooltip
              anchor="center right"
              content-class="bg-accent fs14px"
              self="center left"
              :offset="[10, 10]"
            >
              Сохранить в PDF
            </q-tooltip>
          </q-btn>
        </q-item-section>
      </q-item>

      <q-item
        clickable
        v-close-popup
        @click="print({ doctype: 'ReestrF103', save: false })"
      >
        <q-item-section>
          <q-item-label>Реестр Ф-103</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            class="q-ml-xl bg-ddbtn text-white"
            size="sm"
            unelevated
            dense
            icon="save"
            v-close-popup
            @click.stop="print({ doctype: 'ReestrF103', save: true })"
          >
            <q-tooltip
              anchor="center right"
              content-class="bg-accent fs14px"
              self="center left"
              :offset="[10, 10]"
            >
              Сохранить в EXCEL
            </q-tooltip>
          </q-btn>
        </q-item-section>
      </q-item>
      <q-item
        clickable
        v-close-popup
        @click="print({ doctype: 'ReestrSimple', save: false })"
      >
        <q-item-section>
          <q-item-label>Реестр простой</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            class="q-ml-xl bg-ddbtn text-white"
            size="sm"
            unelevated
            dense
            icon="save"
            v-close-popup
            @click.stop="print({ doctype: 'ReestrSimple', save: true })"
          >
            <q-tooltip
              anchor="center right"
              content-class="bg-accent fs14px"
              self="center left"
              :offset="[10, 10]"
            >
              Сохранить в EXCEL
            </q-tooltip>
          </q-btn>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script>
export default {
  data() {
    return {
      addPrice: false,
      addNdsToPrice: false,
      addNdsToPriceServices: false
    };
  },
  computed: {
    curfid() {
      return this.$store.getters.CURFORMAT;
    },
    formatlist() {
      return this.$store.getters.FORMAT_LIST;
    },
    curformat: {
      get: function() {
        return this.formatlist.filter(pair => pair.value == this.curfid)[0];
      },
      set: function(newval) {
        this.$store.commit("SET_FORMAT", newval);
      }
    },
    s() {
      return this.$store.getters.SETTINGS_OBJECT;
    },
    activeUser() {
      return this.$store.getters.ACTIVEUSER;
    }
  },
  methods: {
    print(doctype) {
      this.$emit("print", doctype);
    },
    save(doctype) {
      this.$emit("save", doctype);
    },
    updateCommonSettings() {
      if (this.activeUser) {
        this.$store.dispatch("SAVE_SETTINGS", {
          id: this.s.getId(),
          settings: JSON.stringify(this.s.getSettingsObject()),
          action: "savesettings",
          common: 1
        });
      }
    },
    openSettings() {}
  }
};
</script>

<style lang="sass">
.bg-ddbtn
  background: rgba(0,0,0, 0.15)
.pagradient
  background-image: linear-gradient(to bottom, #0a8b8e 0%, #252c44 100%)!important

.justify-start-important
  justify-content: start
  margin-top: 10px
</style>
