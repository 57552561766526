<template>
  <q-dialog
    v-model="show"
    persistent
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card class="mypdial text-white invdial">
      <q-card-section class="dialhead">
        <div class="text-h6">{{ parameterText }}</div>
      </q-card-section>
      <q-card-section>
        <div class="row">
          <div class="col" v-if="parameterValueType == 'text'">
            <q-input
              color="accent"
              dark
              v-model="parameterModel"
              dense
              @keyup.enter="done(true)"
              clearable
            />
          </div>
          <div class="col" v-else-if="parameterValueType == 'date'">
            <q-input
              color="accent"
              dark
              v-model="parameterModel"
              dense
              @keyup.enter="done(true)"
              readonly
            >
              <template v-slot:prepend>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date
                      v-model="parameterModel"
                      mask="DD.MM.YYYY"
                      minimal
                      dark
                      bordered
                    >
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
          <div class="col" v-else>
            <div class="float-left">
              <q-input
                color="accent"
                dark
                v-model.number="parameterModel"
                dense
                :mask="mask"
                :suffix="suffix"
                @keyup.enter="done(true)"
                @input="changeDigitModel"
                debounce="400"
                clearable
              />
            </div>
            <div class="float-right" v-if="parameterValueName == 'weight'">
              <q-btn-dropdown
                color="accent"
                label="Веса для писем"
                no-caps
                unelevated
                content-class="text-dark"
                v-model="showListSelector"
              >
                <div class="row mwps q-pa-md">
                  <div class="col">
                    <div class="q-mb-xs">
                      Размер конверта: {{ f.list.fname.toUpperCase() }} ({{
                        envelopeWeight[this.f.list.fname]
                      }}
                      гр)
                    </div>
                    <div class="q-mb-xs">Выберите количество листов А4:</div>
                    <div class="row q-gutter-xs">
                      <div
                        class="col-2 cursor-pointer text-center bg-white rounded-borders llew"
                        v-for="item in 20"
                        :key="item"
                        clickable
                        v-close-popup
                        @click="selectEnvelopeWeight(item)"
                      >
                        {{ item }}
                      </div>
                    </div>
                  </div>
                </div>
              </q-btn-dropdown>
            </div>
          </div>
        </div>
      </q-card-section>

      <q-card-actions
        align="right"
        class="q-px-md q-pt-md q-pb-md text-white dialbtns"
      >
        <q-btn
          unelevated
          label="Ок"
          no-caps
          @click="done(true)"
          color="accent"
        />
        <q-btn
          unelevated
          label="Отмена"
          no-caps
          @click="done(false)"
          color="accent"
          class="q-ml-md"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>


<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    recepientsIds: {
      type: Array,
      default: null
    },
    openedRecep: {
      type: Object,
      default: null
    },
    parameterText: {
      type: String,
      default: ""
    },
    parameterValueName: {
      type: String,
      default: ""
    },
    parameterValueType: {
      type: String,
      default: "text"
    }
  },
  computed: {
    mass() {
      return this.$router.currentRoute.name == "mass";
    },
    f() {
      return this.$store.getters.CURFORMAT_OBJECT;
    },
    suffix() {
      if (this.parameterValueName == "weight") {
        return "грамм";
      } else {
        return "руб";
      }
    },
    mask() {
      if (this.parameterValueName == "weight") {
        return "#####";
      } else {
        return "";
      }
    }
  },
  data() {
    return {
      parameterModel: null,
      listWeight: 5,
      envelopeWeight: {
        c6: 4,
        dl: 5,
        c5: 8,
        c4: 17,
        b4: 21
      },
      showListSelector: false
    };
  },
  watch: {
    show(val) {
      if (val == true) {
        this.parameterModel =
          this.openedRecep !== null
            ? this.openedRecep[this.parameterValueName] != 0
              ? this.openedRecep[this.parameterValueName]
              : null
            : null;
      }
    }
  },

  methods: {
    changeDigitModel() {
      let st = this.parameterModel;
      let fl = parseFloat(st);
      if (isNaN(fl)) {
        this.parameterModel = null;
      } else {
        this.parameterModel = fl.toFixed(2);
        this.parameterModel = parseFloat(this.parameterModel);
      }
    },
    selectEnvelopeWeight(item) {
      this.parameterModel =
        item * this.listWeight + this.envelopeWeight[this.f.list.fname];
      this.showListSelector = false;
    },
    generateSingleParameter() {
      let payload = {};
      let mv = this.parameterModel;
      if (this.parameterModel !== null && this.parameterValueType == "number") {
        if (this.parameterValueName != "weight") {
          mv = this.parameterModel.toFixed(2);
        } else {
          mv = this.parameterModel.toFixed(0);
        }
      }
      if (this.parameterModel === null && this.parameterValueType == "number") {
        mv = 0;
      }
      payload["recep"] = this.openedRecep;
      payload["parameterValueName"] = this.parameterValueName;
      payload["parameterValueType"] = this.parameterValueType;
      payload["parameterValue"] = mv;
      this.$store.commit("SET_SINGLE_PARAM_TO_RECEP", payload);
    },
    async generateSingleParameterMass(clear = false) {
      let payload = {};
      let mv = this.parameterModel;

      if (this.parameterModel !== null && this.parameterValueType == "number") {
        if (this.parameterValueName != "weight") {
          mv = parseFloat(this.parameterModel.toFixed(2));
        } else {
          mv = parseFloat(this.parameterModel.toFixed(0));
        }
      }
      if (this.parameterModel === null && this.parameterValueType == "number") {
        mv = 0;
      }
      if (this.parameterModel === null && this.parameterValueType == "text") {
        mv = "";
      }

      payload[this.parameterValueName] = mv;
      this.$store.dispatch("CHANGE_AND_REPLACE_RECEPIENTS_IN_LIST", {
        selected: this.recepientsIds,
        newData: payload,
        progressText: clear
          ? "Удаляем сведения об отправлении"
          : "Заполняем сведения об отправлении"
      });
    },
    done(payload) {
      if (payload == true) {
        if (this.mass && this.openedRecep === null) {
          this.generateSingleParameterMass();
        } else {
          this.generateSingleParameter();
          this.$store.commit("UPDATE_CANVAS");
        }
      }
      this.$emit("update:show", false);
      if (this.mass) {
        this.$emit("update:openedRecep", null);
      }
    },
    clearSingleParam() {
      let payload = {};
      payload["recep"] = this.openedRecep;
      payload["parameterValueName"] = this.parameterValueName;
      payload["parameterValueType"] = this.parameterValueType;
      payload["parameterValue"] = this.parameterValueType == "text" ? "" : 0;
      this.$store.commit("SET_SINGLE_PARAM_TO_RECEP", payload);
    },
    clearSingleParamMass(paramName) {
      let payload = {};
      if (paramName == "weight") {
        payload.weight = 0;
      }
      if (paramName == "cod") {
        payload.cod = 0;
      }
      if (paramName == "deliveryprice") {
        payload.deliveryprice = 0;
      }
      if (paramName == "additionalprice") {
        payload.additionalprice = 0;
      }
      if (paramName == "note") {
        payload.note = "";
      }
      this.$store.dispatch("CHANGE_AND_REPLACE_RECEPIENTS_IN_LIST", {
        selected: this.recepientsIds,
        newData: payload,
        progressText: "Удаляем сведения об отправлении"
      });
    }
  },
  mounted() {
    this.$eventBus.$on("clear-single-param", this.clearSingleParam);
    this.$eventBus.$on("clear-single-param-mass", this.clearSingleParamMass);
  },
  beforeDestroy() {
    this.$eventBus.$off("clear-single-param");
    this.$eventBus.$off("clear-single-param-mass");
  }
};
</script>

<style lang="sass">
.invdial
  width: 520px
.dialhead, .dialbtns
  background: rgba(0,0,0,0.15)
.spinputnum
  width: 50%
.mwps
  max-width: 250px
.llew
  border: 1px solid #555
.llew:hover
  background: #ccc!important
</style>
